<script setup>
/* eslint-disable */
import { ref } from "vue";
import { Bars3Icon, XMarkIcon } from "@heroicons/vue/24/outline";
const isMenuOpen = ref(false);
</script>

<template>
  <nav class="bg-white border-b fixed w-full z-50">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between h-18">
        <div class="flex items-center">
          <!-- Logo Image -->
          <div class="flex-shrink-0">
            <img
              src="../../assets/lg.png"
              alt="Bank Logo"
              class="h-20 w-20 object-contain"
            />
          </div>

          <!-- Logo Text -->
          <h1 class="text-md font-bold text-red-700 uppercase">MultiMilla</h1>
        </div>

        <!-- Desktop Menu -->
        <div class="flex items-center space-x-8">
          <router-link
            to="/login"
            class="bg-red-700 text-white px-6 py-2 rounded-lg hover:bg-secondary transition"
          >
            Login
          </router-link>
        </div>
      </div>
    </div>
  </nav>
</template>

<style scoped>
a , h1{
  font-family: "Work Sans", sans-serif;
}
</style>
