<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, Pagination, EffectCards } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-cards';

const services = [
  {
    title: 'Instant Airtime',
    description: 'Top up your phone in seconds',
    icon: '📱',
    color: 'bg-blue-50'
  },
  {
    title: 'Data Bundles',
    description: 'Stay connected with affordable data',
    icon: '🌐',
    color: 'bg-purple-50'
  },
  {
    title: 'Bill Payments',
    description: 'Pay bills hassle-free',
    icon: '💡',
    color: 'bg-green-50'
  },
  {
    title: 'TV Subscriptions',
    description: 'Never miss your favorite shows',
    icon: '📺',
    color: 'bg-orange-50'
  }
];
</script>

<template>
  <section class="py-20 bg-gray-50">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="text-center mb-16" data-aos="fade-up">
        <h2 class="text-3xl md:text-4xl font-bold mb-4">
          <span class="text-dark">Our Services</span>
        </h2>
        <p class="text-xl text-gray-600 max-w-2xl mx-auto">
          Experience seamless digital services at your fingertips
        </p>
      </div>
      
      <Swiper
        :modules="[Autoplay, Pagination, EffectCards]"
        :slides-per-view="1"
        :breakpoints="{
          '640': { slidesPerView: 2 },
          '1024': { slidesPerView: 3 }
        }"
        :space-between="30"
        :autoplay="{ delay: 3000, disableOnInteraction: false }"
        :pagination="{ clickable: true }"
        class="service-slider"
      >
        <SwiperSlide v-for="service in services" :key="service.title">
          <div :class="['p-8 rounded-2xl hover-card', service.color]">
            <div class="text-5xl mb-6">{{ service.icon }}</div>
            <h3 class="text-2xl font-semibold mb-3">{{ service.title }}</h3>
            <p class="text-gray-600">{{ service.description }}</p>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  </section>
</template>


<style scoped>
h2,
button,
p {
  font-family: "Work Sans", sans-serif;
}
.service-slider {
  padding-bottom: 4rem;
}

:deep(.swiper-pagination-bullet) {
  @apply w-3 h-3 bg-red-700 opacity-25 transition-all duration-300;
}

:deep(.swiper-pagination-bullet-active) {
  @apply w-6 rounded-full opacity-100;
}
</style>