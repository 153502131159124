<script setup>
import { ref } from "vue";
const stats = ref([
  { number: "1M+", label: "Active Users" },
  { number: "₦500M+", label: "Mon Transactions" },
  { number: "99.9%", label: "Success Rate" },
]);
</script>

<template>
  <section class="relative overflow-hidden pt-32 pb-16 md:pt-40 md:pb-24">
    <!-- Background Elements -->
    <div class="absolute inset-0 overflow-hidden">
      <div
        class="absolute -top-24 -right-24 w-96 h-96 rounded-full bg-red-600 opacity-5"
      ></div>
      <div
        class="absolute top-1/2 left-0 w-72 h-72 rounded-full bg-red-600 opacity-5"
      ></div>
    </div>

    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
      <div class="grid lg:grid-cols-2 gap-12 items-end">
        <!-- Content -->
        <div class="self-start" data-aos="fade-right">
          <h1 class="text-4xl md:text-6xl font-bold mb-6">
            <span class="gradient-text">Seamless Payments</span>
            <br />for Everyone
          </h1>
          <p class="text-xl text-gray-600 mb-8 leading-relaxed">
            Experience lightning-fast transactions for airtime, data bundles,
            and bill payments. Your one-stop solution for all digital services.
          </p>

          <div class="flex flex-wrap gap-2 mb-12 sm:flex-wrap">
            <button
              class="bg-red-700 text-white px-6 py-2 rounded-lg hover:bg-secondary transition"
            >
              Get Started Now
            </button>
          </div>

          <!-- Stats -->
          <div class="grid grid-cols-3 gap-8 mt-16">
            <div v-for="stat in stats" :key="stat.number" class="text-center">
              <div class="text-3xl font-bold gradient-text">
                {{ stat.number }}
              </div>
              <div class="text-sm text-gray-600 mt-1">{{ stat.label }}</div>
            </div>
          </div>
        </div>

        <!-- Hero Image -->
        <div class="relative self-end" data-aos="fade-left">
          <div class="relative z-10 animate-float">
            <img
              src="../../assets/premium_photo-1669879860046-bb73a4c4597b.avif"
              alt="A man with a beard and glasses holding a pen"
              class="rounded-3xl shadow-2xl w-full h-[500px] object-cover"
            />
          </div>

          <!-- Decorative Elements -->
          <div
            class="absolute top-1/2 right-0 transform translate-x-1/4 -translate-y-1/2 w-72 h-72 bg-primary opacity-10 rounded-full blur-3xl"
          ></div>
          <div
            class="absolute -bottom-8 -left-8 w-64 h-64 bg-secondary opacity-10 rounded-full blur-3xl"
          ></div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
h1,
button,
p {
  font-family: "Work Sans", sans-serif;
}
</style>
