<template>
  <div
    class="min-h-screen flex flex-col items-center justify-center bg-white p-4"
  >
    <!-- Logo -->
    <div class="">
      <img src="../assets/lg.png" alt="Bank Logo" class="h-15 w-20" />
    </div>

    <!-- Registration Form -->
    <div class="w-full max-w-md space-y-6">
      <div class="text-center text-gray-700 text-lg mb-8">Create Account</div>

      <!-- Stepper Indicators -->
      <div class="flex justify-center space-x-4 mb-6">
        <div
          class="w-10 h-2 rounded-full transition-colors"
          :class="currentStep === 1 ? 'bg-red-600' : 'bg-gray-300'"
        ></div>
        <div
          class="w-10 h-2 rounded-full transition-colors"
          :class="currentStep === 2 ? 'bg-red-600' : 'bg-gray-300'"
        ></div>
      </div>

      <form @submit.prevent="handleSubmit" class="space-y-4">
        <!-- Step 1: Basic Information -->
        <template v-if="currentStep === 1">
          <!-- Full Name Input -->
          <div class="relative">
            <input
              type="text"
              placeholder="Full Name"
              v-model="formData.full_name"
              class="w-full px-4 py-4 bg-gray-100/100 rounded-lg border-none focus:outline-none focus:ring-0 text-gray-700 text-lg placeholder:text-gray-400 shadow-sm"
              :class="{ 'border-red-500 border': firstnameError }"
            />
            <div class="input-background"></div>
            <div class="input-shadow"></div>
            <p v-if="firstnameError" class="text-red-500 text-sm mt-1 px-1">
              {{ firstnameError }}
            </p>
          </div>

          <!-- Phone Number Input -->
          <div class="relative">
            <input
              type="tel"
              placeholder="Phone Number"
              v-model="formData.phone"
              @focus="phoneError = null"
              class="w-full px-4 py-4 bg-gray-100/100 rounded-lg border-none focus:outline-none focus:ring-0 text-gray-700 text-lg placeholder:text-gray-400 shadow-sm"
              :class="{ 'border-red-500 border': phoneError }"
            />
            <div class="input-background"></div>
            <div class="input-shadow"></div>
            <p v-if="phoneError" class="text-red-500 text-sm mt-1 px-1">
              {{ phoneError }}
            </p>
          </div>

          <!-- Email Input -->
          <div class="relative">
            <input
              type="email"
              placeholder="Email Address"
              v-model="formData.email"
              class="w-full px-4 py-4 bg-gray-100/100 rounded-lg border-none focus:outline-none focus:ring-0 text-gray-700 text-lg placeholder:text-gray-400 shadow-sm"
              :class="{ 'border-red-500 border': emailError }"
            />
            <div class="input-background"></div>
            <div class="input-shadow"></div>
            <p v-if="emailError" class="text-red-500 text-sm mt-1 px-1">
              {{ emailError }}
            </p>
          </div>

          <!-- Password Input -->
          <div class="relative">
            <input
              type="password"
              placeholder="Create Password"
              v-model="formData.password"
              class="w-full px-4 py-4 bg-gray-100/100 rounded-lg border-none focus:outline-none focus:ring-0 text-gray-700 text-lg placeholder:text-gray-400 shadow-sm"
              :class="{ 'border-red-500 border': passwordError }"
            />
            <div class="input-background"></div>
            <div class="input-shadow"></div>
            <p v-if="passwordError" class="text-red-500 text-sm mt-1 px-1">
              {{ passwordError }}
            </p>
          </div>

          <!-- Password Requirements -->
          <div class="text-sm text-gray-500 space-y-1 px-1 text-right">
            <p>Password must contain:</p>
            <ul class="space-y-1">
              <li class="flex items-center gap-2 justify-end">
                <div
                  class="w-1.5 h-1.5 rounded-full"
                  :class="hasMinLength ? 'bg-red-500' : 'bg-gray-300'"
                ></div>
                At least 6 characters
              </li>
            </ul>
          </div>

          <!-- Next Button for Step 1 -->
          <button
            type="button"
            @click="proceedToNextStep"
            class="w-full bg-red-700 text-white py-4 rounded-full hover:bg-red-500 transition-colors font-medium text-lg mt-8 flex justify-center items-center"
            :disabled="!isFirstStepValid"
            :class="{ 'opacity-50 cursor-not-allowed': !isFirstStepValid }"
          >
            Next
          </button>
        </template>

        <!-- Step 2: Additional Information -->
        <template v-if="currentStep === 2">
          <!-- BVN Input -->
          <div class="relative">
            <input
              type="text"
              placeholder="BVN"
              v-model="formData.bvn"
              class="w-full px-4 py-4 bg-gray-100/100 rounded-lg border-none focus:outline-none focus:ring-0 text-gray-700 text-lg placeholder:text-gray-400 shadow-sm"
              :class="{ 'border-red-500 border': bvnError }"
            />
            <div class="input-background"></div>
            <div class="input-shadow"></div>
            <p v-if="bvnError" class="text-red-500 text-sm mt-1 px-1">
              {{ bvnError }}
            </p>
          </div>

          <!-- Nin Input -->
          <div class="relative">
            <input
              type="text"
              placeholder="NIN"
              v-model="formData.nin"
              class="w-full px-4 py-4 bg-gray-100/100 rounded-lg border-none focus:outline-none focus:ring-0 text-gray-700 text-lg placeholder:text-gray-400 shadow-sm"
              :class="{ 'border-red-500 border': ninError }"
            />
            <div class="input-background"></div>
            <div class="input-shadow"></div>
            <p v-if="ninError" class="text-red-500 text-sm mt-1 px-1">
              {{ ninError }}
            </p>
          </div>

          <div>
            <p v-if="phoneError" class="text-red-500 text-sm mt-1 px-1">
              {{ phoneError }}
            </p>
          </div>

          <!-- Back and Submit Buttons -->
          <div class="flex space-x-4">
            <button
              type="button"
              @click="currentStep = 1"
              class="w-1/2 bg-gray-200 text-gray-700 py-4 rounded-full hover:bg-gray-300 transition-colors font-medium text-lg mt-8"
            >
              Back
            </button>
            <button
              type="submit"
              class="w-1/2 bg-red-600 text-white py-4 rounded-full transition-colors font-medium text-lg mt-8 flex justify-center items-center"
              :disabled="!isFormValid || loading"
              :class="{
                'opacity-50 cursor-not-allowed': !isFormValid || loading,
              }"
            >
              <Spinner v-if="loading" class="mr-2" />
              <span v-else>Create Account</span>
            </button>
          </div>
        </template>
      </form>

      <div class="text-center pt-6">
        <span class="text-login"
          >Have an account ?
          <router-link to="/login" class="text-red-800 font-medium"
            >Login
          </router-link></span
        >
      </div>

      <!-- Success and Error Modals -->
      <SuccessModal
        :show-success-modal="showSuccessModal"
        :account-id="accountId"
        :message="successMessage"
        :duration="duration"
        @close="handleModalClose"
        @continue="handleContinue"
      />
      <ErrorModal
        :show-error-modal="showErrorModal"
        :err-message="errorMessage"
        :duration="duration"
        @close="handleModalClose"
        @continue="handleContinue"
      />

      <!-- Custom Date Picker Modal -->
      <CustomModal
        :show-modal="showModal"
        @close="handleModalClose"
        title="Select Date of Birth"
      >
        <div class="p-4">
          <CustomDatePicker
            @select="handleDateSelect"
            :initial-date="formData.date_of_birth || new Date()"
          />
          <div class="flex justify-end space-x-2 mt-6">
            <button
              @click="showModal = false"
              class="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
            >
              Cancel
            </button>
            <button
              @click="confirmDateOfBirth"
              class="px-4 py-2 bg-red-700 text-white rounded hover:bg-red-500"
            >
              Confirm
            </button>
          </div>
        </div>
      </CustomModal>
    </div>
  </div>
</template>

<script setup>
/*eslint-disable*/
import { ref, computed } from "vue";
import Spinner from "@/components/SpinnerComp.vue";
import SuccessModal from "@/components/SuccessModal.vue";
import CustomModal from "@/components/CustomModal.vue";
import ErrorModal from "@/components/ErrorModal.vue";
import CustomDatePicker from "@/components/CustomDatePicker.vue";

import { useHttp } from "@/composables/useHttp";
import { Preferences } from "@capacitor/preferences";
import { useRouter } from "vue-router";

const loading = ref(false);
const router = useRouter();
const { httpRequest } = useHttp();

const showSuccessModal = ref(false);
const showErrorModal = ref(false);
const showModal = ref(false);
const successMessage = ref(
  "Your account has been successfully created click the button below to continue"
);
const errorMessage = ref("");

const currentStep = ref(1);

const formData = ref({
  full_name: "",
  phone: "",
  email: "",
  password: "",
  bvn: "",
  nin: "",
});

const tempSelectedDate = ref(null);

// errors
const passwordError = ref(null);
const firstnameError = ref(null);
const phoneError = ref(null);
const emailError = ref(null);
const bvnError = ref(null);
const ninError = ref(null);

// server Error
const serverError = ref(null);

// Password validation computed properties
const hasMinLength = computed(() => formData.value.password.length >= 6);

const isValidPhone = (phone) => {
  const phoneRegex = /^[0-9]{11}$/;
  return phoneRegex.test(phone);
};

const isFirstStepValid = computed(() => {
  return (
    formData.value.full_name.length > 0 &&
    isValidEmail(formData.value.email) &&
    formData.value.phone.length == 11 &&
    hasMinLength.value
  );
});

const isFormValid = computed(() => {
  return (
    isFirstStepValid.value &&
    formData.value.bvn.length == 11 &&
    formData.value.nin.length == 11
  );
});

const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const proceedToNextStep = () => {
  if (isFirstStepValid.value) {
    currentStep.value = 2;
  }
};

// Handle date selection
const handleDateSelect = (date) => {
  tempSelectedDate.value = date;
};

const clearErrors = () => {
  emailError.value = null;
  firstnameError.value = null;
  passwordError.value = null;
  bvnError.value = null;
  ninError.value = null;
  phoneError.value = null;
};

const handleSubmit = async () => {
  if (isFormValid.value) {
    clearErrors();
    loading.value = true;

    try {
      const response = await httpRequest(
        "POST",
        "/auth/registerCus",
        formData.value
      );
      if (response.data.error || response.status == 500) {
        if (response.data.error) {
          errorMessage.value = response.data.error;
        }
        if (response.data.messages) {
          const errorMessages = response.data.messages;
          if (errorMessages.phone) {
            phoneError.value = errorMessages.phone[0];
          }
          if (errorMessages.email) {
            emailError.value = errorMessages.email[0];
          }
          if (errorMessages.full_name) {
            firstnameError.value = errorMessages.full_name[0];
          }
          if (errorMessages.password) {
            passwordError.value = errorMessages.password[0];
          }
          if (errorMessages.bvn) {
            bvnError.value = errorMessages.bvn[0];
          }
          if (errorMessages.nin) {
            dobError.value = errorMessages.nin[0];
          }
          return;
        } else {
          serverError.value = "Something went wrong !";
          showErrorModal.value = true;
          console.log("error");
        }
      } else {
        showSuccessModal.value = true;
      }
    } catch (error) {
      const genericError = "An unexpected error occurred.";
      phoneError.value = genericError;
      emailError.value = genericError;
      firstnameError.value = genericError;
      passwordError.value = genericError;
      bvnError.value = genericError;
      ninError.value = genericError;
    } finally {
      loading.value = false;
    }
  }
};

const handleModalClose = () => {
  showSuccessModal.value = false;
  showErrorModal.value = false;
  showModal.value = false;
};

const handleContinue = () => {
  showSuccessModal.value = false;
  router.push("/login");
};

const confirmDateOfBirth = () => {
  if (tempSelectedDate.value) {
    const date = tempSelectedDate.value;
    const formattedDate = `${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;

    formData.value.date_of_birth = formattedDate;
    showModal.value = false;
  }
};

const openDateOfBirthModal = () => {
  showModal.value = true;
};
</script>

<style scoped>
h1,
a,
div,
button,
p {
  font-family: "Work Sans", sans-serif;
}
</style>
