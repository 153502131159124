<script setup>
import { onMounted } from 'vue';
import AOS from 'aos';
import 'aos/dist/aos.css';
import NavBar from '@/components/home/NavBar.vue';
import Hero from '@/components/home/HeroComponent.vue';
import ServiceSlider from '@/components/home/ServiceSlider.vue';
import Features from '@/components/home/FeaturesPage.vue';
import Testimonials from '@/components/home/TestimonialaComp.vue';
import Footer from '@/components/home/FooterComponent.vue';
import DownloadApp from '@/components/home/DownloadApp.vue';

onMounted(() => {
  AOS.init({
    duration: 1000,
    easing: 'ease-in-out',
    once: true,
    // Add mobile-specific settings if needed
    mobile: true,
    disable: false
  });
});
</script>

<template>
  <div class="flex flex-col min-h-screen w-full overflow-x-hidden">
    <NavBar class="sticky top-0 z-50" />
    
    <main class="flex-grow w-full">
      <section class="px-4 sm:px-6 lg:px-8 w-full">
        <Hero class="w-full" />
      </section>
      
      <section class=" w-full">
        <ServiceSlider class="w-full" />
      </section>
      
      <section class="px-4 sm:px-6 lg:px-8 w-full">
        <Features class="w-full" />
      </section>
      
      <section class=" w-full">
        <Testimonials class="w-full" />
      </section>
      
      <section class=" w-full">
        <DownloadApp class="w-full" />
      </section>
    </main>
    
    <Footer class="w-full" />
  </div>
</template>

<style>
/* Ensure full width and prevent horizontal scrolling */
html, body {
  max-width: 100%;
  overflow-x: hidden;
}
</style>