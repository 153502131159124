<script setup >
// import { CheckCircleIcon } from '@heroicons/vue/24/solid';

const features = [
  {
    title: 'Instant Transactions',
    description: 'Get your airtime and data instantly after payment',
    icon: '⚡'
  },
  {
    title: 'Secure Payments',
    description: 'Your transactions are protected with bank-grade security',
    icon: '🔒'
  },
  {
    title: '24/7 Support',
    description: 'Our customer support team is always available to help',
    icon: '🎯'
  },
  {
    title: 'Best Rates',
    description: 'Enjoy competitive rates and amazing discounts',
    icon: '💎'
  }
];
</script>

<template>
  <section class="py-20 bg-white">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="text-center mb-16" data-aos="fade-up">
        <h2 class="text-3xl font-bold text-gray-900 mb-4">Why Choose Us</h2>
        <p class="text-lg text-gray-600">Experience the best VTU services with our platform</p>
      </div>

      <div class="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
        <div v-for="(feature, index) in features" 
             :key="feature.title"
             :data-aos="'fade-up'"
             :data-aos-delay="index * 100"
             class="bg-gray-50 p-6 rounded-xl hover:transform hover:scale-105 transition-all duration-300">
          <div class="text-4xl mb-4">{{ feature.icon }}</div>
          <h3 class="text-xl font-semibold mb-2 text-gray-900">{{ feature.title }}</h3>
          <p class="text-gray-600">{{ feature.description }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
h2,
button,
p {
  font-family: "Work Sans", sans-serif;
}
</style>