<template>
  <div
    class="min-h-screen flex flex-col items-center justify-center bg-white p-4"
  >
    <!-- Logo -->
    <div>
      <img src="../assets/lg.png" alt="Bank Logo" class="h-15 w-20" />
    </div>

    <!-- Login Form -->
    <div class="w-full max-w-md space-y-6">
      <!-- Username Display -->
      <div class="text-center text-gray-700 text-lg mb-8">Welcome back</div>

      <!-- Password Input -->
      <div class="space-y-4">
        <div class="relative">
          <input
            type="number"
            placeholder="Enter Phone Number"
            v-model="phoneNumber"
            @focus="phoneError = null"
            class="w-full px-4 py-4 bg-gray-100/100 border-none focus:outline-none focus:ring-0 text-gray-700 text-lg placeholder:text-gray-400 shadow-sm"
            :class="{
              'letter-spacing-wide': phoneNumber.length > 0,
            }"
          />
          <div class="absolute inset-0 rounded-xl bg-gray-100/50 -z-10"></div>
          <div
            class="absolute inset-0 rounded-xl shadow-inner-custom -z-10"
          ></div>
          <p v-if="phoneError" class="text-red-500 text-sm mt-1 px-1">
            {{ phoneError }}
          </p>
        </div>
      </div>

      <!-- Password Input -->
      <div class="space-y-4">
        <div class="relative">
          <input
            type="password"
            placeholder="Enter Password"
            v-model="password"
            class="w-full px-4 py-4 bg-gray-100/100 border-none focus:outline-none focus:ring-0 text-gray-700 text-lg placeholder:text-gray-400 shadow-sm"
            :class="{
              'letter-spacing-wide': password.length > 0,
            }"
          />
          <div class="absolute inset-0 rounded-xl bg-gray-100/50 -z-10"></div>
          <div
            class="absolute inset-0 rounded-xl shadow-inner-custom -z-10"
          ></div>
        </div>

        <div class="text-right">
          <a href="#" class="text-red-600 text-sm font-medium"
            >Forgot Password?</a
          >
        </div>
      </div>

      <!-- Login Button -->
      <button
        @click="handleLogin"
        class="w-full bg-red-600 text-white py-4 rounded-full transition-colors font-medium text-lg mt-8 flex justify-center items-center"
      >
        <Spinner v-if="loading" class="mr-2" />
        <span v-else>login</span>
      </button>

      <!-- Switch Account Link -->
      <div class="text-center pt-6">
        <router-link to="/join" class="text-gray-600 font-medium"
          >Create new account</router-link
        >
      </div>
    </div>
    <ErrorModal
      :show-error-modal="showErrorModal"
      :duration="duration"
      :errMessage="errorMessage"
      @close="handleModalClose"
      @continue="handleContinue"
    />

    <SuccessModal
      :show-success-modal="showSuccessModal"
      :account-id="accountId"
      :duration="duration"
      @close="handleModalClose"
      @continue="handleContinue"
    />
  </div>
</template>

<script setup>
/*eslint-disable*/
import { ref } from "vue";
import Spinner from "@/components/SpinnerComp.vue";
import ErrorModal from "@/components/ErrorModal.vue";
import SuccessModal from "@/components/SuccessModal.vue";

import { useHttp } from "@/composables/useHttp";
import { Preferences } from "@capacitor/preferences";
import { useRouter } from "vue-router";

const router = useRouter();
const { httpRequest } = useHttp();

const showSuccessModal = ref(false);
const showErrorModal = ref(false);

const errorMessage = ref("");
const phoneNumber = ref("");
const password = ref("");
const phoneError = ref(null);
const passwordError = ref(null);
const loading = ref(false);

const handleLogin = async () => {
  loading.value = true;
  phoneError.value = null;
  passwordError.value = null;
  errorMessage.value = null;
  try {
    const payLoad = {
      phone: phoneNumber.value,
      password: password.value,
    };

    const response = await httpRequest("POST", "/auth/loginCus", payLoad);
    loading.value = false;

    if (response.data.errors) {
      const errorData = response.data.errors;
      if (errorData.phone) {
        phoneError.value = errorData.phone[0];
      }
      if (errorData.password) {
        passwordError.value = errorData.password[0];
      }
      return;
    }

    // Check for invalid credentials
    if (response.data.invalid) {
      errorMessage.value = response.data.invalid;
      showErrorModal.value = true;

      return;
    }

    // Store the token and navigate
    showSuccessModal.value = true;

    await Preferences.set({
      key: "token",
      value: response.data.token,
    });

    router.push("/wallet");
  } catch (error) {
    loading.value = false;
    phoneError.value = "An unexpected error occurred.";
    passwordError.value = "An unexpected error occurred.";
  }
};

const handleModalClose = () => {
  showErrorModal.value = false;
};

const handleContinue = () => {
  showErrorModal.value = false;
  router.push("/login"); // or wherever you want to redirect
};
</script>

<style scoped>
.shadow-inner-custom {
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
}

.letter-spacing-wide {
  letter-spacing: 8px;
}

/* Custom autofill styling */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px #fafafa inset !important;
  -webkit-text-fill-color: #374151 !important;
  caret-color: #374151 !important;
}

/* Hide password reveal button in Edge/IE */
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

/* Custom placeholder styling */
::placeholder {
  font-weight: 400;
  opacity: 0.7;
}
h1,
a,
div,
button,
p {
  font-family: "Work Sans", sans-serif;
}
</style>
