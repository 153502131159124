import { Preferences } from "@capacitor/preferences";

export async function authMiddleware(to, from, next) {
    try {
        const { value: authToken } = await Preferences.get({ key: "token" });
        if (!authToken) {
            return next({ name: 'login' });
        }
        next();
    } catch (error) {
        console.error('Error in authMiddleware:', error);
        next({ name: "login" });
    }
}


