<script setup>
import { computed, defineProps } from "vue";
import TransactionCard from "@/components/TransactionCard.vue";

const props = defineProps({
  transactions: {
    type: Array,
    required: true,
  },
  activeTab: {
    type: String,
    required: true,
    validator: (value) => ["all", "airtime", "data"].includes(value),
  },
});

const filteredTransactions = computed(() => {
  if (props.activeTab === "all") return props.transactions;
  return props.transactions.filter((t) => t.type === props.activeTab);
});
</script>

<template>
  <div class="space-y-4">
    <div
      v-if="!transactions?.length"
      class="flex flex-col items-center justify-center p-10 text-center"
    >
      <span class="material-icons text-gray-400 text-4xl mb-4">receipt</span>
      <p class="text-gray-600 mb-2">No transactions yet</p>
      <p class="text-sm text-gray-500">
        Your recent transactions will appear here
      </p>
    </div>
    <TransactionCard
      v-for="transaction in filteredTransactions"
      :key="transaction.id"
      :transaction="transaction"
    />
  </div>
</template>
