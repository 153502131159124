<!-- AirtimePage.vue -->
<template>
  <!-- Balance Card -->
  <div class="bg-white max-w-2xl mx-auto p-4">
    <div class="mb-4 mt-6">
      <h2 class="text-sm text-gray-500 it-text">
        {{ customer.full_name }}
      </h2>
      <!-- <p class="text-lg font-semibold text-navy-900 it-des">
        {{ customer.email }}
      </p> -->
    </div>
    <div
      class="shadow p-4 text-white rounded-lg py-6 bg-red-600 transition-colors "
    >
      <h2 class="text-sm mb-2 tot-bal text-gray-100 font-bold">TOTAL BALANCE</h2>
      <div class="flex justify-between items-center">
        <div class="flex items-center">
          <span class="mr-2 text-gray-100">₦</span>
          <span class="text-gray-100" v-if="!showBalance">*****</span>
          <span v-else class="text-gray-100 font-bold">{{ balance }}</span>
        </div>
        <button @click="toggleBalance" class="flex items-center">
          <span class="mr-2 show-txt text-gray-100">View </span>
          <div
            :class="[
              'w-12 h-6 rounded-full p-1',
              showBalance ? 'bg-green-400' : 'bg-gray-300',
            ]"
          >
            <div
              :class="[
                'w-4 h-4 rounded-full bg-white transform transition-transform',
                showBalance ? 'translate-x-6' : '',
              ]"
            ></div>
          </div>
        </button>
      </div>
    </div>

    <!-- Account Info -->
    <div class="px-4 bg-white mt-4">
      <div class="">
        <!-- Ledger Balance Section -->
        <!-- <div
        class="border-b border-gray-200 p-4 flex justify-between items-center bg-gray-50"
      >
        <span class="text-gray-600 show-txt">Ledger Balance: Hidden</span>
        <button class="text-gray-600 flex items-center">
          <span class="material-icons">history</span>
          <span class="ml-1 show-txt">History</span>
        </button>
      </div> -->

        <!-- eaZyLinks Section -->
        <div class="py-4 mt-4 bg-white">
          <div class="grid grid-cols-4 gap-4 mb-6 mt-4">
            <QuickLink
              v-for="link in quickLinks"
              :key="link.title"
              :icon="link.icon"
              :title="link.title"
              :path="link.path"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
/*eslint-disable*/
import { ref, onMounted, computed } from "vue";
import QuickLink from "@/components/QuickLink.vue";
import { useStore } from "vuex";

const store = useStore();
const customer = computed(() => store.getters.customer);

const showBalance = ref(false);
const balance = ref("0");
const accountBalance = ref("150,000.00");
const activeTab = ref("Overview");

const quickLinks = ref([
  { title: "Airtime", icon: "phone", path: "/merchant/airtime" },
  { title: "Data", icon: "wifi", path: "/merchant/data" },
  { title: "Cable TV", icon: "tv", path: "/wallet/coming" },
  { title: "Betting", icon: "sports_soccer", path: "/wallet/coming" },
  // { title: "Finance", icon: "account_balance", path: "finance" },
  { title: "Settings", icon: "settings", path: "/wallet/profile" },
  // { title: "Withdraw", icon: "wallet", path: "/payout" },
]);

const navItems = ref([
  { title: "Overview", icon: "dashboard" },
  { title: "Airtime", icon: "smartphone" },
  { title: "Transfer", icon: "swap_horiz" },
  { title: "Bills", icon: "receipt_long" },
]);

const toggleBalance = () => {
  showBalance.value = !showBalance.value;
};

const setActiveTab = (tab) => {
  activeTab.value = tab;
};

onMounted(async () => {
  await store.dispatch("fetchCustomer");
});
</script>

<style scoped>
h1,h2,p,
.show-txt,
.tot-bal,
.btn-txt {
  font-family: "Work Sans", sans-serif;
}
</style>
