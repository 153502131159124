export const getInitials = (name) => {
    return name.charAt(0).toUpperCase();
};

export const formatNumber = (number) => {
    return number.toLocaleString();
};

function generateRandomSuffix(length) {
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let suffix = '';
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        suffix += charset[randomIndex];
    }
    return suffix;
}

export const generateRequestId = (suffixLength = 16) => {
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    const hour = currentDate.getHours().toString().padStart(2, '0');
    const minutes = currentDate.getMinutes().toString().padStart(2, '0');

    const suffix = generateRandomSuffix(suffixLength);
    return `${year}${month}${day}${hour}${minutes}${suffix}`;
}