<template>
  <div class="max-w-2xl mx-auto p-4">
    <div class="mt-5">
      <h1 class="text-2xl font-bold text-gray-800 mb-6">Transaction History</h1>
      <TransactionTabs v-model:activeTab="activeTab" />
      <TransactionList
        :transactions="customerTransactions"
        :activeTab="activeTab"
      />
    </div>
  </div>
</template>

<script setup>
/*eslint-disable*/

import { ref, onMounted, computed } from "vue";
import Spinner from "@/components/SpinnerComp.vue";
import { Preferences } from "@capacitor/preferences";
import TransactionTabs from "@/components/TransactionTabs.vue";
import TransactionList from '@/components/TransactionList.vue';

import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useHttp } from "@/composables/useHttp";

const store = useStore();
const router = useRouter();
const { httpRequest } = useHttp();

const activeTab = ref("all");

const customerTransactions = computed(
  () => store.getters.customer?.transactions
);
const loading = ref(false);
const authenticationErrorMsg = ref(null);

onMounted(async () => {
  await store.dispatch("fetchCustomer");
});
</script>

<style>
.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
}
h1 {
  font-family: "Work Sans", sans-serif;
}
</style>
