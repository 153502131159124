<template>
  <div class="flex justify-center bg-white min-h-screen">
    <!-- Desktop Left Sidebar -->
    <div
      class="hidden lg:block w-64 min-h-screen bg-white border-r border-gray-200 fixed left-0 top-0"
    >
      <div class="p-6">
        <img src="../assets/lg.png" alt="Bank Logo" class="h-12 w-24 mb-8" />
        <nav class="space-y-5">
          <router-link
            v-for="item in navItems"
            :key="item.title"
            :to="item.path"
            class="flex items-center p-3 rounded-lg hover:bg-gray-100 cursor-pointer"
            :class="{ 'bg-gray-100': activeTab === item.title }"
            @click="setActiveTab(item.title)"
          >
            <span class="material-icons mr-3">{{ item.icon }}</span>
            <span class="font-semibold pg-title">{{ item.title }}</span>
          </router-link>
        </nav>
      </div>
    </div>

    <!-- Main Content Area -->
    <div class="w-full lg:ml-64 lg:mr-64 lg:max-w-4xl flex justify-center">
      <div class="w-full max-w-md lg:max-w-none h-screen flex flex-col">
        <!-- Fixed Header - Hidden on desktop -->
        <header
          class="bg-white p-4 flex justify-between items-center fixed top-0 z-10 w-full max-w-md mx-auto lg:hidden"
        >
          <button v-if="!isBaseRoute" class="text-dark" @click="goBack">
            <span class="material-icons text-dark">arrow_back</span>
          </button>
          <button v-else class="text-dark">
            <img src="../assets/lg.png" alt="Bank Logo" class="h-10 w-20" />
          </button>
          <p class="text-dark text-lg font-semibold">{{ pageTitle }}</p>
        </header>

        <!-- Desktop Header -->
        <header
          class="hidden lg:flex bg-white p-6 items-center border-b border-gray-200"
        >
          <h1 class="text-2xl font-semibold">{{ pageTitle }}</h1>
        </header>

        <!-- Scrollable Content Area -->
        <main
          class="flex-1 overflow-y-auto pt-16 pb-20 lg:pt-0 lg:pb-0 items-center justify-center"
        >
          <router-view />
        </main>

        <!-- Fixed Bottom Navigation - Hidden on desktop -->
        <nav
          class="fixed bottom-0 left-0 right-0 bg-white border-t z-10 max-w-md mx-auto lg:hidden"
        >
          <div class="flex justify-around p-4">
            <NavItem
              v-for="item in navItems"
              :key="item.title"
              :icon="item.icon"
              :title="item.title"
              :path="item.path"
              :active="item.title === activeTab"
              @click="setActiveTab(item.title)"
            />
          </div>
        </nav>
      </div>
    </div>

    <!-- Desktop Right Sidebar -->
    <div
      class="hidden lg:block w-64 min-h-screen bg-white border-l border-gray-200 fixed right-0 top-0"
    >
      <div class="p-6">
        <h2 class="text-xl font-bold mb-6 text-center">Recent Activities</h2>
        <div class="space-y-4 max-h-[60vh] md:max-h-[600px] overflow-y-auto">
          <RecentActivity :transactions="customerTransactions" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import NavItem from "@/components/NavItem.vue";
import RecentActivity from "@/components/RecentActivity.vue";
import { useStore } from "vuex";

const route = useRoute();
const router = useRouter();
const store = useStore();
const customerTransactions = computed(
  () => store.getters.customer?.transactions
);

const pageTitle = computed(() => route.meta.title || "Default Title");
const isBaseRoute = ref(route.path === "/wallet");
const activeTab = ref("Overview");

const navItems = ref([
  { title: "Overview", icon: "dashboard", path: "/wallet" },
  { title: "Statements", icon: "receipt_long", path: "/wallet/statements" },
  { title: "Profile", icon: "person", path: "/wallet/profile" },
]);

const setActiveTab = (tab) => {
  activeTab.value = tab;
};

const goBack = () => {
  router.back();
};

// Watch for route changes to update the base route condition
watch(
  () => route.path,
  (newPath) => {
    isBaseRoute.value = newPath === "/";
  }
);
</script>

<style scoped>
h1,
a,
.show-txt,
h2,
h3,
.pg-title, .btn-txt {
  font-family: "Work Sans", sans-serif;
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  white-space: nowrap;
}

main::-webkit-scrollbar {
  display: none;
}

main {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>
