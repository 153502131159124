<template>
  <div class="max-w-lg mx-auto p-6">
    <!-- Profile Details -->
    <div class="space-y-2 mt-5">
      <div
        v-for="item in profileItems"
        :key="item.id"
        class="flex items-center justify-between p-4 rounded-lg transition-colors"
      >
        <div class="flex items-center gap-4">
          <div class="w-8 h-8 flex items-center justify-center text-gray-600">
            <span class="material-icons">{{ item.icon }}</span>
          </div>
          <div>
            <h2 class="text-sm text-gray-500 it-text">
              {{ item.label }}
            </h2>
            <p class="text-sm font-semibold text-navy-900 it-des">
              {{ item.value }}
            </p>
          </div>
        </div>
      </div>

      <!-- Linked Banks Section -->
      <router-link
        to="/wallet/banks"
        class="flex items-center justify-between p-4 rounded-lg transition-colors cursor-pointer"
      >
        <div class="flex items-center gap-4">
          <div class="w-8 h-8 flex items-center justify-center text-gray-600">
            <span class="material-icons">account_balance</span>
          </div>
          <div>
            <h2 class="text-sm font-semibold text-navy-900 it-text">Banks</h2>
            <p class="text-gray-500 text-sm it-des">
              Manage your bank accounts
            </p>
          </div>
        </div>
        <span class="material-icons text-gray-400">chevron_right</span>
      </router-link>
    </div>

    <!-- Logout Button -->
    <div class="mt-8">
      <button
        class="w-full bg-red-600 text-white py-4 rounded-full hover:bg-red-800 transition-colors font-bold text-sm mt-8 flex justify-center items-center"
        @click.prevent="handleLogout"
      >
        <Spinner v-if="loading" class="mr-2" />
        <span v-else class="flex items-center gap-1">
          Sign Out
          <span class="material-icons text-gray-100">arrow_forward</span>
        </span>
      </button>
    </div>

    <p class="text-center text-gray-400 text-sm mt-8 app-ver">
      {{ authenticationErrorMsg }}
    </p>
    <!-- <p class="text-center text-gray-400 text-sm mt-8 app-ver">
      App edition {{ appVersion }}
    </p> -->
  </div>
</template>

<script setup>
/*eslint-disable*/

import { ref, onMounted, computed } from "vue";
import Spinner from "@/components/SpinnerComp.vue";
import { Preferences } from "@capacitor/preferences";

import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useHttp } from "@/composables/useHttp";

const store = useStore();
const router = useRouter();
const { httpRequest } = useHttp();

const customer = computed(() => store.getters.customer);
const loading = ref(false);
const authenticationErrorMsg = ref(null);

const appVersion = ref("7.2.261");
const profileItems = computed(() => [
  {
    id: 1,
    label: "Full Name",
    value: `${customer.value?.full_name}`,
    icon: "person",
  },
  {
    id: 2,
    label: "Email",
    value: customer.value?.email || "Not provided",
    icon: "mail",
  },
  {
    id: 3,
    label: "Date Joined",
    value: customer.value?.dateJoined
      ? new Date(customer.value.dateJoined).toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })
      : "Not available",
    icon: "calendar_today",
  },
]);

const handleLogout = async () => {
  loading.value = true;
  try {
    const response = await httpRequest("POST", "/customer/signOutCustomer");
    if (response.status || response.status == 200) {
      await Preferences.remove({ key: "token" });
      router.push("/login");
      return;
    } else {
      handleErrorResponse(response.status);
    }
  } catch (error) {
    handleErrorResponse(error);
  } finally {
    loading.value = false;
  }
};

const handleErrorResponse = (errorCode) => {
  switch (errorCode) {
    case 400:
      authenticationErrorMsg.value = "Bad request. Please check your inputs.";
      break;
    case 401:
      authenticationErrorMsg.value = "unauthenticated.";
      break;
    case 404:
      authenticationErrorMsg.value = "service not found.";
      break;
    case 500:
      authenticationErrorMsg.value = "Server error. Please try again later.";
      break;
    default:
      authenticationErrorMsg.value = "An unexpected error occurred.";
  }
};

onMounted(async () => {
  await store.dispatch("fetchCustomer");
});
</script>

<style>
.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
}

.it-text,
.app-ver,
p,
button {
  font-family: "Work Sans", sans-serif;
}
</style>
