<script setup>
const currentYear = new Date().getFullYear();

const footerLinks = {
  Services: ["Airtime Recharge", "Data Bundles", "Bill Payments", "Cable TV"],
  Company: ["About Us", "Careers", "Blog", "Press"],
  Support: ["Help Center", "Terms of Service", "Privacy Policy", "Contact"],
  Connect: ["Twitter", "Facebook", "Instagram", "LinkedIn"],
};
</script>

<template>
  <footer class="bg-gray-900 text-gray-300">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <!-- Footer Grid -->
      <div class="grid grid-cols-2 md:grid-cols-4 gap-8 mb-8">
        <div v-for="(links, category) in footerLinks" :key="category">
          <h3 class="text-white font-semibold mb-4">{{ category }}</h3>
          <ul class="space-y-2">
            <li v-for="link in links" :key="link">
              <a
                href="#"
                class="hover:text-primary transition-colors duration-200"
                >{{ link }}</a
              >
            </li>
          </ul>
        </div>
      </div>

      <!-- Newsletter -->
      <div class="border-t border-gray-800 pt-8 mb-8">
        <div class="max-w-md">
          <h3 class="text-white font-semibold mb-4">Reach Out To Us</h3>
          <div class="space-y-3 gap-2">
            <div>
              <h5>Office Address</h5>

              <p>
                45, ZIK’S AVENUE, AWKA OPPOSITE NIG. PRISONS, AMAWBIA, ANAMBRA
                STATE, NIGERIA
              </p>
            </div>
            <div>
              <h5>Contact No.</h5>
              <p>09154063995</p>
            </div>
          </div>
        </div>
      </div>

      <!-- Bottom Bar -->
      <div
        class="border-t border-gray-800 pt-8 flex flex-col md:flex-row justify-between items-center gap-4"
      >
        <p>&copy; {{ currentYear }} MultiMilla. All rights reserved.</p>
        <div class="flex space-x-6">
          <a
            v-for="platform in footerLinks.Connect"
            :key="platform"
            href="#"
            class="hover:text-primary transition-colors duration-200"
          >
            {{ platform }}
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped>
h1,h5,a
button,
p {
  font-family: "Work Sans", sans-serif;
}
</style>
