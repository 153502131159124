<template>
  <div class="max-w-md mx-auto p-6 bg-white">
    <!-- Form -->
    <form @submit.prevent="handleSubmit" class="space-y-8">
      <!-- Network Provider Selector -->
      <div>
        <label class="block text-gray-500 mb-3">Network</label>
        <!-- Selected Provider Display -->
        <button
          type="button"
          @click="isModalOpen = true"
          class="w-full p-3 flex items-center justify-between p-4 rounded-lg bg-gray-100/100 transition-colors"
        >
          <div class="flex items-center gap-3">
            <img
              v-if="selectedProvider"
              :src="getSelectedProviderDetails?.image"
              :alt="selectedProvider"
              class="h-8 w-8 object-contain"
            />
            <span class="text-gray-400 capitalize select-txt">
              {{ selectedProvider || "select Network" }}
            </span>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5 text-gray-400"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </div>

      <!-- Data Packages Grid -->
      <div v-if="isFetchingItemRequest">
        <LoaderComp />
      </div>
      <div
        v-if="!isFetchingItemRequest && dataPackages.length > 0"
        class="space-y-2"
      >
        <label class="block text-gray-500 mb-3">Select Data Package</label>
        <div class="flex justify-between">
          <div class="px-4 py-2">{{ selectedPackageName }}</div>
          <button
            @click.prevent="isDataModalOpen = true"
            class="px-4 py-2 text-blue-600 hover:text-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2 transition"
          >
            See Data Plans
          </button>
        </div>
      </div>

      <!-- Rest of the form remains the same -->
      <!-- Phone Number -->
      <div class="space-y-2">
        <label class="block text-gray-500 mb-3">Phone </label>
        <div class="flex items-center space-x-2">
          <div
            class="flex items-center gap-2 p-4 rounded-lg bg-gray-100/100 transition-colors"
          >
            <span class="text-gray-500 select-txt">+234</span>
          </div>
          <input
            type="tel"
            v-model="phoneNumber"
            placeholder="Enter Phone No."
            class="flex-1 p-4 rounded-lg bg-gray-100/100 transition-colors placeholder:font-custom"
          />
        </div>
      </div>

      <!-- Submit Button -->
      <button
        type="submit"
        :disabled="!isFormValid"
        class="w-full p-4 text-white text-lg rounded-full font-semibold bg-red-600 disabled:opacity-50 disabled:cursor-not-allowed flex justify-center items-center"
      >
        <Spinner v-if="loading" class="mr-2" />
        <span v-else>Pay</span>
      </button>
    </form>

    <!-- Responsive Modal -->
    <Teleport to="body">
      <Transition name="modal">
        <div
          v-if="isModalOpen"
          class="fixed inset-0 z-50 flex md:items-center md:justify-center"
        >
          <!-- Backdrop -->
          <div
            class="absolute inset-0 bg-black bg-opacity-25"
            @click="isModalOpen = false"
          ></div>

          <!-- Modal Content -->
          <div
            class="absolute bottom-0 left-0 right-0 bg-white md:relative md:max-w-lg md:mx-auto rounded-xl transform transition-transform duration-300"
          >
            <div class="flex justify-between items-center p-4">
              <h3 class="text-lg font-semibold provider-text">
                Select Provider
              </h3>
              <button
                @click="isModalOpen = false"
                class="p-2 hover:bg-gray-100 rounded-full transition-colors"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <!-- Provider List -->
            <div class="p-4">
              <div
                class="grid grid-cols-2 gap-3 max-h-[60vh] md:max-h-[400px] overflow-y-auto"
              >
                <button
                  v-for="provider in providers"
                  :key="provider.serviceID"
                  type="button"
                  @click="selectProviderFromModal(provider)"
                  :class="[
                    'py-3 px-3 border-0 text-center transition-colors ',
                    selectedProvider === provider.serviceID
                      ? 'bg-red-800 text-white hover:bg-red-700'
                      : 'bg-gray-50 hover:bg-gray-200',
                  ]"
                >
                  <img
                    :src="provider.image"
                    :alt="provider.serviceID"
                    class="h-8 mx-auto mb-2"
                  />
                  <span
                    class="block text-sm capitalize text-gray-500 provider-text"
                    >{{ provider.serviceID }}</span
                  >
                </button>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </Teleport>

    <!-- Responsive Modal -->
    <Teleport to="body">
      <Transition name="modal">
        <div
          v-if="isDataModalOpen"
          class="fixed inset-0 z-50 flex md:items-center md:justify-center"
        >
          <!-- Backdrop -->
          <div
            class="absolute inset-0 bg-black bg-opacity-25"
            @click.prevent="isDataModalOpen = false"
          ></div>

          <!-- Modal Content -->
          <div
            class="absolute bottom-0 left-0 right-0 bg-white md:relative md:max-w-lg md:mx-auto rounded-xl transform transition-transform duration-300"
          >
            <div class="flex justify-between items-center p-4">
              <h3 class="text-lg font-semibold">Select Data Plan</h3>
              <button
                @click.prevent="isDataModalOpen = false"
                class="p-2 hover:bg-gray-100 rounded-full transition-colors"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <!-- Provider List -->
            <div class="p-4">
              <div
                class="grid grid-cols-2 gap-3 max-h-[60vh] md:max-h-[400px] overflow-y-auto"
              >
                <button
                  v-for="pkg in dataPackages"
                  :key="pkg.variation_code"
                  type="button"
                  @click="selectDataPackage(pkg)"
                  :class="[
                    'p-4 rounded-lg border transition-colors text-left',
                    selectedPackage === pkg.variation_code
                      ? 'bg-red-800 text-white border-red-800'
                      : 'bg-white border-gray-200 hover:border-red-800',
                  ]"
                >
                  <div class="text-lg font-semibold provider-text">
                    ₦ {{ pkg.variation_amount }}
                  </div>
                  <div class="text-sm provider-text">{{ pkg.name }}</div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </Teleport>
    <SuccessModal
      :show-success-modal="showSuccessModal"
      :duration="duration"
      :message="successMessage"
      :isRegistration="false"
      @close="handleModalClose"
      @continue="handleContinue"
    />
    <ErrorModal
      :show-error-modal="showErrorModal"
      :errMessage="serverError"
      :duration="duration"
      @close="handleModalClose"
      @continue="handleContinue"
    />
  </div>
</template>

<script setup>
/* eslint-disable */
import { ref, computed, onMounted } from "vue";
import Spinner from "@/components/SpinnerComp.vue";
import SuccessModal from "@/components/SuccessModal.vue";
import ErrorModal from "@/components/ErrorModal.vue";
import LoaderComp from "@/components/LoaderComp.vue";

import { useHttp } from "@/composables/useHttp";
import { generateRequestId } from "@/utils/globals";

const { httpRequest } = useHttp();

const showSuccessModal = ref(false);
const showErrorModal = ref(false);
const successMessage = ref("Data purcahase successful");

const loading = ref(false);
const isFetchingItemRequest = ref(false);
const serverError = ref(null);

const providers = ref([]);
const selectedProvider = ref(null);
const selectedPackage = ref(null);
const selectedPackageName = ref(null);
const isModalOpen = ref(false);
const isDataModalOpen = ref(false);
const phoneNumber = ref(null);
const customAmount = ref("");
const dataPackages = ref([]);
const varationCode = ref(null);

const totalAmount = computed(() => Number(customAmount.value) || 0);

const getSelectedProviderDetails = computed(() => {
  return providers.value.find((p) => p.serviceID === selectedProvider.value);
});

const isFormValid = computed(() => {
  return selectedProvider.value && phoneNumber.value && selectedPackage.value;
});

const fetchServiceAirtimeIds = async () => {
  try {
    const response = await httpRequest(
      "GET",
      `/customer/serviceVariations/data`
    );
    providers.value = response.data.content;
  } catch (error) {
    console.log("error");
  }
};

const getServiceVariations = async () => {
  try {
    const response = await httpRequest(
      "GET",
      `/customer/dataServiceVariations/${selectedProvider.value}`
    );
    if (response.data?.content?.varations) {
      dataPackages.value = response.data.content.varations;
      isFetchingItemRequest.value = false;
    }
  } catch (error) {
    console.log("error");
  }
};

const selectProviderFromModal = (provider) => {
  selectedProvider.value = provider.serviceID;
  isFetchingItemRequest.value = true;
  getServiceVariations();
  isModalOpen.value = false;
};

const selectDataPackage = (pkg) => {
  selectedPackage.value = pkg.variation_code;
  selectedPackageName.value = pkg.name;
  console.log(selectedPackage.value);
};

const handleSubmit = async () => {
  if (!isFormValid.value) return;
  try {
    loading.value = true;
    const requestPayload = {
      variation_code: selectedPackage.value,
      request_id: generateRequestId(),
      billersCode: "",
      serviceID: selectedProvider.value,
      phone: phoneNumber.value,
    };
    const response = await httpRequest(
      "POST",
      "/customer/buy-data",
      requestPayload
    );
    // Check if the response has data and error fields
    if (response.status !== 200 || response.data?.error) {
      if (response.data?.message) {
        serverError.value = response.data.message; // Show specific error message
      } else {
        serverError.value = "Something went wrong!"; // Default error message
      }
      showErrorModal.value = true;
      return;
    }

    // Assuming success response structure - handle transaction status
    const transactionStatus = response.data?.content?.transactions?.status;
    if (transactionStatus === "delivered") {
      showSuccessModal.value = true;
    } else if (transactionStatus === "failed") {
      serverError.value = "Airtime Purchase Failed!";
      showErrorModal.value = true;
    } else {
      serverError.value = "Unexpected transaction status!";
      showErrorModal.value = true;
    }
  } catch (error) {
    console.log("error");
  } finally {
    loading.value = false;
  }
};

const handleModalClose = () => {
  showSuccessModal.value = false;
  showErrorModal.value = false;
};

onMounted(async () => {
  await fetchServiceAirtimeIds();
});
</script>

<style scoped>
label,
input,
button,
.select-txt,
.provider-text,
h3 {
  font-family: "Work Sans", sans-serif;
}

.font-custom {
  font-family: "Work Sans", sans-serif;
}

/* Modal Transitions */
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.3s ease;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}

.modal-enter-active .transform,
.modal-leave-active .transform {
  transition: transform 0.3s ease;
}

/* Mobile animation - slides from bottom */
@media (max-width: 767px) {
  .modal-enter-from .transform {
    transform: translateY(100%);
  }

  .modal-leave-to .transform {
    transform: translateY(100%);
  }
}

/* Desktop animation - fades in and scales up */
@media (min-width: 768px) {
  .modal-enter-from .transform {
    transform: scale(0.95);
  }

  .modal-leave-to .transform {
    transform: scale(0.95);
  }
}
</style>
