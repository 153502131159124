<script setup>
const testimonials = [
  {
    name: "Sarah Johnson",
    role: "Regular Customer",
    content:
      "QuickTopUp has made recharging my phone so much easier. The process is fast and reliable!",
    image: "https://randomuser.me/api/portraits/women/1.jpg",
  },
  {
    name: "Michael Chen",
    role: "Business Owner",
    content:
      "Managing utility bills for my business has never been easier. Highly recommended!",
    image: "https://randomuser.me/api/portraits/men/1.jpg",
  },
  {
    name: "Emma Wilson",
    role: "Student",
    content:
      "The data bundles are affordable and the service is super fast. Perfect for students!",
    image: "https://randomuser.me/api/portraits/women/2.jpg",
  },
];
</script>

<template>
  <section class="py-20 bg-gray-50">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="text-center mb-16" data-aos="fade-up">
        <h2 class="text-3xl font-bold text-gray-900 mb-4">
          What Our Customers Say
        </h2>
        <p class="text-lg text-gray-600">Don't just take our word for it</p>
      </div>

      <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
        <div
          v-for="(testimonial, index) in testimonials"
          :key="testimonial.name"
          :data-aos="'fade-up'"
          :data-aos-delay="index * 200"
          class="bg-white p-6 rounded-xl shadow-md hover:shadow-xl transition-shadow duration-300"
        >
          <div class="flex items-center mb-4">
            <img
              :src="testimonial.image"
              :alt="testimonial.name"
              class="w-12 h-12 rounded-full mr-4 object-cover"
            />
            <div>
              <h4 class="font-semibold text-gray-900">
                {{ testimonial.name }}
              </h4>
              <p class="text-gray-600 text-sm">{{ testimonial.role }}</p>
            </div>
          </div>
          <p class="text-gray-700 italic">"{{ testimonial.content }}"</p>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
h2,
h4,
button,
p {
  font-family: "Work Sans", sans-serif;
}
</style>
