<script setup>
/*eslint-disable*/
import { defineProps, defineEmits } from "vue";

const props = defineProps({
  activeTab: {
    type: String,
    required: true,
    validator: (value) => ["all", "airtime", "data"].includes(value),
  },
});

const emit = defineEmits(["update:activeTab"]);
</script>

<template>
  <div class="flex space-x-2 mb-6">
    <button
      v-for="tab in ['all', 'airtime', 'data']"
      :key="tab"
      @click="emit('update:activeTab', tab)"
      :class="[
        'px-4 py-2 rounded-lg text-sm font-medium transition-colors',
        activeTab === tab
          ? 'bg-red-800 text-white'
          : 'bg-gray-100 text-gray-600 hover:bg-gray-200',
      ]"
    >
      {{ tab.charAt(0).toUpperCase() + tab.slice(1) }}
    </button>
  </div>
</template>

<style scoped>
button {
  font-family: "Work Sans", sans-serif;
}
</style>
