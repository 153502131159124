import { createStore } from 'vuex'
import { useHttp } from '@/composables/useHttp';
import { handleAuthError } from '@/utils/errorHandler';
import { useRouter } from 'vue-router';
// import { Preferences } from '@capacitor/preferences';

export default createStore({
  state: {
    customer: []
  },
  mutations: {
    SET_CUSTOMER(state, customerData) {
      state.customer = customerData;
    },
  },
  actions: {
    async fetchCustomer({ state, commit }) {
      if (state.customer.length > 0) {
        console.log('Customer data already fetched');
        return;
      } else {
        console.log('Fetching customer data');
        const { httpRequest } = useHttp();
        const router = useRouter();
        try {
          const response = await httpRequest('GET', `/customer/customer`);
          if (response.data.message === 'Unauthenticated.' || response.status === 401) {
            handleAuthError({ response: { status: 401, data: response.data }, message: 'Unauthenticated' }, router);
            return;
          }
          commit('SET_CUSTOMER', response.data.data);
        } catch (error) {
          handleAuthError(error, router);
        }
      }
    }
  },
  getters: {
    customer: (state) => state.customer,
    
  }
})
