<template>
  <div>
    <Transition
      enter-active-class="ease-out duration-300"
      enter-from-class="opacity-0 translate-y-full"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="ease-in duration-200"
      leave-from-class="opacity-100 translate-y-0"
      leave-to-class="opacity-0 translate-y-full"
    >
      <div
        v-if="showModal"
        class="fixed inset-0 bg-black bg-opacity-50 flex items-end md:items-center md:justify-center z-50"
        @click.self="closeModal"
      >
        <div
          class="bg-white w-full md:max-w-md rounded-t-3xl md:rounded-3xl p-6 space-y-6"
        >
          <div class="text-center space-y-2">
            <slot></slot>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup>
/*eslint-disable*/

import { ref } from "vue";
import { useRouter } from "vue-router";

const props = defineProps({
  showModal: Boolean,
  message: {
    type: String,
  },
  isRegistration: {
    type: Boolean,
  },
  accountId: {
    type: String,
    default: "0.0.120214",
  },
  duration: {
    type: String,
    default: "1h",
  },
});

const emit = defineEmits(["close"]);

const router = useRouter();
const confettiColors = [
  "bg-emerald-500",
  "bg-blue-500",
  "bg-yellow-500",
  "bg-red-500",
  "bg-purple-500",
  "bg-pink-500",
  "bg-orange-500",
  "bg-indigo-500",
];

const closeModal = () => {
  emit("close");
};
</script>

<style scoped>
@keyframes confetti-1 {
  0% {
    transform: translate(0, 0) rotate(45deg);
  }
  100% {
    transform: translate(-20px, -20px) rotate(225deg);
    opacity: 0;
  }
}
@keyframes confetti-2 {
  0% {
    transform: translate(0, 0) rotate(45deg);
  }
  100% {
    transform: translate(20px, -20px) rotate(225deg);
    opacity: 0;
  }
}
@keyframes confetti-3 {
  0% {
    transform: translate(0, 0) rotate(45deg);
  }
  100% {
    transform: translate(-20px, 20px) rotate(225deg);
    opacity: 0;
  }
}
@keyframes confetti-4 {
  0% {
    transform: translate(0, 0) rotate(45deg);
  }
  100% {
    transform: translate(20px, 20px) rotate(225deg);
    opacity: 0;
  }
}
@keyframes confetti-5 {
  0% {
    transform: translate(0, 0) rotate(45deg);
  }
  100% {
    transform: translate(0, -30px) rotate(225deg);
    opacity: 0;
  }
}
@keyframes confetti-6 {
  0% {
    transform: translate(0, 0) rotate(45deg);
  }
  100% {
    transform: translate(-30px, 0) rotate(225deg);
    opacity: 0;
  }
}
@keyframes confetti-7 {
  0% {
    transform: translate(0, 0) rotate(45deg);
  }
  100% {
    transform: translate(30px, 0) rotate(225deg);
    opacity: 0;
  }
}
@keyframes confetti-8 {
  0% {
    transform: translate(0, 0) rotate(45deg);
  }
  100% {
    transform: translate(0, 30px) rotate(225deg);
    opacity: 0;
  }
}

.animate-confetti-1 {
  animation: confetti-1 1s ease-out forwards;
}
.animate-confetti-2 {
  animation: confetti-2 1s ease-out forwards;
}
.animate-confetti-3 {
  animation: confetti-3 1s ease-out forwards;
}
.animate-confetti-4 {
  animation: confetti-4 1s ease-out forwards;
}
.animate-confetti-5 {
  animation: confetti-5 1s ease-out forwards;
}
.animate-confetti-6 {
  animation: confetti-6 1s ease-out forwards;
}
.animate-confetti-7 {
  animation: confetti-7 1s ease-out forwards;
}
.animate-confetti-8 {
  animation: confetti-8 1s ease-out forwards;
}
</style>
