<template>
  <div class="p-4 max-w-6xl mx-auto">
    <!-- Accounts Grid -->
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div
        v-for="account in accounts"
        :key="account.id"
        class="relative bg-red-800 rounded-lg p-4 text-white shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
      >
        <div class="flex items-start justify-between">
          <!-- Left side: Bank Icon and Account Details -->
          <div class="flex-1">
            <!-- Bank Icon -->
            <!-- <div class="bg-red-700 rounded-full px-4 py-1 inline-block mb-2">
              <span class="material-symbols-outlined h-4">account_balance</span>
            </div> -->

            <!-- Account Number -->
            <h2 class="text-xl font-mono mb-1">
              {{ formatAccountNumber(account.account_number) }}
            </h2>
            <p class="text-xs text-blue-100 mb-2">Account Number</p>

            <!-- Account Holder and Type -->
            <div class="flex justify-between items-end">
              <div class="uppercase tracking-wider text-sm">
                <p class="font-medium truncate">{{ account.account_name }}</p>
              </div>
              <div class="text-right">
                <p class="text-sm uppercase font-medium">
                  {{ account.bank_name }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Empty State -->
    <div v-if="!accounts.length" class="text-center py-8">
      <p class="text-gray-500 text-base">No accounts found</p>
    </div>
  </div>
</template>

<script setup>
import { onMounted, computed } from "vue";

import { useStore } from "vuex";

const store = useStore();
const accounts = computed(() => store.getters.customer?.accounts || []);

const formatAccountNumber = (number) => {
  return number.replace(/(.{4})/g, "$1 ").trim();
};

onMounted(async () => {
  await store.dispatch("fetchCustomer");
});
</script>

<style scoped>
h1,
h2,
p,
.show-txt,
.tot-bal,
.btn-txt {
  font-family: "Work Sans", sans-serif;
}
</style>
