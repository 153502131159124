import { createRouter, createWebHistory } from 'vue-router'
import WalletView from '../views/WalletView.vue'
import AirtimeView from '../views/AirtimeView.vue'
import DataView from '../views/DataView.vue'
import SettingsView from '../views/SettingsView'
import PayoutView from '../views/PayoutView'
import FinanceView from '../views/FinanceView'
import CompanyDetailView from '../views/CompanyDetailView'
import LoginView from '../views/LoginView.vue'
import RegistrationView from '../views/RegistrationView.vue'
import BanksView from '../views/BanksView.vue'
import StatementsView from '../views/StatementsView.vue'
import ComingSoonView from '../views/ComingSoonView.vue'


import BaseLayout from '@/layouts/BaseLayout.vue';
import MerchantLayout from '@/layouts/MerchantLayout.vue';
import DetailsLayout from '@/layouts/DetailsLayout.vue';

import { authMiddleware } from '@/middlewares';
import HomepageView from '@/views/HomepageView.vue'


const routes = [
  {
    path: '/',
    name: 'home-view',
    component: HomepageView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/join',
    name: 'join',
    component: RegistrationView
  },
  {
    path: '/wallet',
    component: BaseLayout,
    beforeEnter: authMiddleware,
    children: [
      {
        path: '',
        component: WalletView,
        meta: { title: 'Overview', transition: 'slide-right' },
      },
      {
        path: 'data',
        component: DataView,
        meta: { title: 'Data', transition: 'slide-left' },
      },
      {
        path: 'profile',
        component: SettingsView,
        meta: { title: 'Settings', transition: 'slide-left' },
      },
      {
        path: 'statements',
        component: StatementsView,
        meta: { title: 'Statements', transition: 'slide-left' },
      },
      {
        path: 'coming',
        component: ComingSoonView,
        meta: { title: 'Coming Soon', transition: 'slide-left' },
      },
      {
        path: 'banks',
        name: 'my-banks',
        component: BanksView,
        meta: { title: 'My Banks', transition: 'slide-left' },
      },
      {
        path: 'finance',
        component: FinanceView,
        meta: { title: 'Finance', transition: 'slide-left' },
      },


    ],
  },
  {
    path: '/payout',
    component: MerchantLayout,
    beforeEnter: authMiddleware,
    children: [
      {
        path: '',
        component: PayoutView,
        meta: { title: 'Payout', transition: 'slide-right' },
      },

    ],
  },
  {
    path: '/merchant',
    component: MerchantLayout,
    beforeEnter: authMiddleware,
    children: [
      {
        path: 'airtime',
        component: AirtimeView,
        meta: { title: 'Buy Airtime', transition: 'slide-left' },
      },
      {
        path: 'data',
        component: DataView,
        meta: { title: 'Buy Data', transition: 'slide-left' },
      },

    ],
  },

  {
    path: '/details',
    component: DetailsLayout,
    beforeEnter: authMiddleware,
    children: [
      {
        path: '',
        component: PayoutView,
        meta: { title: 'Payout', transition: 'slide-right' },
      },
      {
        path: 'company/:id',
        component: CompanyDetailView,
        meta: { title: 'Package Details', transition: 'slide-left' },
      },

    ],
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
